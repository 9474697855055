<template>
  <div>
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item active>行銷應用中心</b-breadcrumb-item>
          <b-breadcrumb-item :to="{ name: 'RewardCardList' }">集點卡管理</b-breadcrumb-item>
          <b-breadcrumb-item active>{{ isReadOnly ? "檢視集點卡" : isEditing ? '編輯集點卡' : '新增集點卡' }}</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading">
        <h4 class="mb-3 font-weight-bold">{{ isReadOnly ? "檢視集點卡" : isEditing ? '編輯集點卡' : '新增集點卡' }}</h4>
        <div class="mb-2">
          <validation-error-alert
            v-if="validationErrors"
            :errors="validationErrors"
          ></validation-error-alert>
          <!-- 通路 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="通路"
            label-for="merchant"
          >
            <b-form-select
              v-model="form.merchant_id"
              :options="merchants"
              :disabled="false == merchantsAreFetched || isReadOnly"
            ></b-form-select>
            <b-form-invalid-feedback :state="!deepGet(v$, 'form.merchant_id.$error')">
              此欄位為必填
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- 通路 End -->
          <!-- 集點卡名稱 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="集點卡名稱"
          >
            <b-form-input
              type="text"
              :state="deepGet(v$, 'form.title.$error') ? false: null"
              v-model="form.title"
              :readonly="isReadOnly"
            ></b-form-input>
            <b-form-invalid-feedback :state="!deepGet(v$, 'form.title.$error')">
              此欄位為必填
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- 集點卡名稱 End -->
          <!-- 使用期間 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="使用期間"
          >
            <div class="d-flex align-items-center">
              <datepicker
                placeholder="選擇日期"
                v-model="form.start_at"
                bootstrap-styling
                :language="zh"
                format="yyyy-MM-dd"
                :disabled="isReadOnly"
                :clear-button="!isReadOnly"
                :input-class="deepGet(v$, 'form.start_at.$error') ? 'is-invalid' : ''"
              ></datepicker>
              <span class="mx-2">~</span>
              <datepicker
                placeholder="選擇日期"
                v-model="form.end_at"
                bootstrap-styling
                :language="zh"
                format="yyyy-MM-dd"
                :disabled="isReadOnly"
                :clear-button="!isReadOnly"
                :input-class="deepGet(v$, 'form.end_at.$error') ? 'is-invalid' : ''"
              ></datepicker>
            </div>
            <b-form-invalid-feedback
              :state="!deepGet(v$, 'form.start_at.beforeEndAt.$invalid')"
            >
              結束時間必須大於起始時間
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- 使用期間 End -->
          <!-- 卡面點數 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="卡面點數"
          >
            <b-form-input
              type="text"
              :state="deepGet(v$, 'form.point_number.$error') ? false: null"
              v-model="form.point_number"
              :readonly="isReadOnly"
              style="width: 200px"
            ></b-form-input>
            <b-form-invalid-feedback :state="!deepGet(v$, 'form.point_number.$error')">
              此欄位為必填
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- 卡面點數 End -->
          <!-- 參加權限 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="參加權限"
          >
            <b-form-checkbox v-model="form.config.permissions" value="registered" :disabled="isReadOnly">需註冊</b-form-checkbox>
          </b-form-group>
          <!-- 參加權限 End -->
          <!-- 活動代碼 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="活動代碼"
          >
            <b-form-input
              type="text"
              v-model="form.event_code"
              :readonly="isReadOnly"
              style="width: 200px"
            ></b-form-input>
          </b-form-group>
          <!-- 活動代碼 End -->
          <!-- 注意事項 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="注意事項"
          >
            <TrixEditor v-model="form.memo" :readonly="isReadOnly"/>
          </b-form-group>
          <!-- 注意事項 End -->
          <!-- 是否啟用分店下拉 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="是否啟用分店下拉"
          >
            <b-form-checkbox v-model="form.config.gift_section.branch_list.enable" :value="true" :unchecked-value="false" :disabled="isReadOnly"></b-form-checkbox>
          </b-form-group>
          <!-- 是否啟用分店下拉 End -->
          <!-- 分店下拉選單來源 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="分店下拉選單來源"
          >
            <b-form-input
              type="text"
              v-model="form.config.gift_section.branch_list.branch_provider"
              :readonly="isReadOnly"
              style="width: 200px"
            ></b-form-input>
          </b-form-group>
          <!-- 分店下拉選單來源 End -->
          <!-- 分店下拉選單文字 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="分店下拉選單文字"
          >
            <b-form-input
              type="text"
              v-model="form.config.gift_section.branch_list.default_select_text"
              :readonly="isReadOnly"
              style="width: 200px"
            ></b-form-input>
          </b-form-group>
          <!-- 分店下拉選單文字 End -->
          <!-- 是否顯示分店代號 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="是否顯示分店代號"
          >
            <b-form-checkbox v-model="form.config.gift_section.branch_list.show_branch_code" :value="true" :unchecked-value="false" :disabled="isReadOnly"></b-form-checkbox>
          </b-form-group>
          <!-- 是否顯示分店代號 End -->
          <!-- 商品是否使用庫存 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="商品是否使用庫存"
          >
            <b-form-checkbox v-model="form.config.gift_section.branch_list.enable_product_stock" :value="true" :unchecked-value="false" :disabled="isReadOnly"></b-form-checkbox>
          </b-form-group>
          <!-- 商品是否使用庫存 End -->
        </div>
        <!-- 欄位部分 End -->
        <!-- 表單底部 Start -->
        <div class="d-flex justify-content-center">
          <b-button
            class="mr-3"
            @click="cancel"
            variant="outline-danger"
          >
            返回
          </b-button>
          <b-button
            @click="submit"
            variant="success"
            v-if="false == isReadOnly"
          >
            儲存
          </b-button>
        </div>
        <!-- 表單底部 End -->
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { isBefore } from "date-fns";
import { defaultsDeep } from "lodash";
import moment from "moment";
import { mapGetters, mapState } from 'vuex';
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import merchantApi from "@/apis/merchant";
import rewardCardApi from "@/apis/reward-card";
import TrixEditor from "@/components/TrixEditor";
import deepGet from "@/utils/deepGet";
import * as consts from "@/consts";

export default {
  components: {
    Datepicker,
    TrixEditor,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        merchant_id: { required },
        title: { required },
        point_number: { required },
        start_at: {
          beforeEndAt: () => {
            if (!this.form.end_at || !this.form.start_at) {
              return true
            }

            return isBefore(new Date(this.form.start_at), new Date(this.form.end_at));
          },
        },
      },
    };
  },
  data() {
    return {
      zh,
      showLoading: true,
      merchantsAreFetched: false,
      merchants: [],
      form: {
        merchant_id: null,
        title: null,
        start_at: null,
        end_at: null,
        point_number: null,
        config: {
          permissions: ["registered"],
          gift_section: {
            branch_list: {
              enable: false,
              enable_product_stock: false,
              branch_provider: "",
              show_branch_code: "",
              default_select_text: "",
            },
          },
        },
        layout_type: 'default',
        point_style: 'default',
        verify_type: 'branch_code',
        event_code: null,
        memo: null,
      },
      validationErrors: null,
    };
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
    ...mapState("general", ['defaultMerchantType']),
    id() {
      return this.$route.params.reward_card_id;
    },
    isEditing() {
      return !!this.id;
    },
    isReadOnly() {
      return this.$route.name == "RewardCardView";
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.fetchMerchants(),
        this.id ? this.fetchRewardCard() : () => {},
      ]);
    } catch (e) {
      this.$swal("錯誤", "讀取資料錯誤", "error");
      this.$router.replace({name: "RewardCardList", query: this.routeQuery});
    }

    this.showLoading = false;
  },
  methods: {
    deepGet,
    isPureObject(value) {
      return typeof value === 'object' && !Array.isArray(value) && value !== null;
    },
    cancel() {
      this.$router.push({ name: 'RewardCardList', query: this.routeQuery });
    },
    async fetchMerchants() {
      this.merchantsAreFetched = false;
      const { data } = await merchantApi.list();
      this.merchantsAreFetched = true;

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: merchant.type_name
              ? `${merchant.name} (${merchant.type_name})`
              : merchant.name,
          };
        }),
      ];

      if (!this.id) {
        const defaultMerchant = data.data.find((merchant) => merchant.type === this.defaultMerchantType) ??
          data.data.find((merchant) => merchant.type === consts.MERCHANT_TYPES.LINE_TYPE) ;

        if (defaultMerchant && !this.form.merchant_id) {
          this.form.merchant_id = defaultMerchant.id;
        }
      }
    },
    async fetchRewardCard() {
      const { data } = await rewardCardApi.get(this.id);

      console.log([...Object.keys(data.data.config)])

      Object.keys(this.form).forEach(key => {
        if (this.isPureObject(this.form[key])) {
          this.$set(this.form, key, defaultsDeep(deepGet(data, `data[${key}]`, {}), this.form[key]));
        } else {
          this.$set(this.form, key, deepGet(data, `data[${key}]`, this.form[key]));
        }
      })

      console.log(this.form)
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return false;

      try {
        this.showLoading = true;

        if (this.isEditing) {
          const response = await rewardCardApi.update({
            id: this.id,
            ...this.form,
            start_at: this.form.start_at ? moment(this.form.start_at).format("YYYY-MM-DD 00:00:00") : null,
            end_at: this.form.end_at ? moment(this.form.end_at).format("YYYY-MM-DD 23:59:59") : null,
          });

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.$router.push({name: "RewardCardList", query: this.routeQuery});
          }
        } else {
          const response = await rewardCardApi.store({
            ...this.form,
            start_at: this.form.start_at ? moment(this.form.start_at).format("YYYY-MM-DD 00:00:00") : null,
            end_at: this.form.end_at ? moment(this.form.end_at).format("YYYY-MM-DD 23:59:59") : null,
          });

          if (response.status && response.status >= 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.$router.push({name: "RewardCardList", query: this.routeQuery});
          }
        }
      } catch (error) {
        this.showLoading = false;

        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
          return
        }

        this.$swal.fire({title: this.isEditing ? "更新失敗" : "新增失敗", type: "error",});
      }
    },
  },
};
</script>
